#app {
  max-width: 1200px;
  margin: 0 auto;
}

nav {
  padding: 20px 0 20px 0;
}

a.active {
  color: #171717;
  text-decoration: underline;
  text-decoration-thickness: 0.17em;
  font-weight: 600;
  text-decoration-color: #497acf;
}
a {
  text-decoration: none;
  color: #171717;
  font-weight: 400;
}

@media (max-width: 940px) {
  .carousel {
    max-width: 940px;
    min-height: 350px;
  }
}
/** Footer **/
.Footer-color {
  background-color: rgba(40, 40, 40);
  color: rgb(219, 216, 216);
}

/*** Social icons Horizontal ***/
.horizontalNoBullets {
  display: flex;
  list-style: none;
  line-height: 2em;
  margin-inline-start: 9%;
}

.horizontalNoBullets li {
  margin-right: 17%;
}

.noBullets {
  list-style: none;
  padding-left: 0;
}
